<template>
  <b-row class="hp-authentication-page">
    <left-item />
    <b-col cols="12" lg="6" class="py-sm-64 py-lg-0">
      <b-row align-v="center" align-h="center" class="h-100 mx-4 mx-sm-n32">
        <b-col
          cols="12"
          md="9"
          xl="7"
          class="col-xxxl-5 px-8 px-sm-0 pt-24 pb-48"
        >
          <!-- <h2 class="mb-0 mb-sm-10 text-center">  ยินดีต้อนรับเข้าสู่ <br/>ระบบบริหารจัดการวารสาร</h2>
          <p class="mt-sm-8 mt-sm-0 text-black-60 text-center">
            วารสารนวัตกรรมสังคมและการเรียนรู้ตลอดชีวิต
          </p> -->

          <h2 class="mb-0 mb-sm-10 text-center">  ยินดีต้อนรับเข้าสู่ระบบ </h2>
         

          <b-form class="mt-16 mt-sm-32 mb-8">
            <b-form-group
              label="อีเมล :"
              label-for="email"
              class="mb-16"
            >
              <b-form-input id="email"  v-model="email" type="email" placeholder="กรอกอีเมล"></b-form-input>
            </b-form-group>

            <b-form-group
              label="รหัสผ่าน :"
              label-for="loginPassword"
              class="mb-16"
            >
              <b-form-input id="loginPassword" v-model="password" type="password" placeholder="กรอกรหัสผ่าน"></b-form-input>
            </b-form-group>

          

            <b-button to="/" type="button" variant="primary" class="w-100" :disabled="loading" v-on:click="handleLogin()">
             เข้าสู่ระบบ
            </b-button>
          </b-form>

          <footer-item />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
} from "bootstrap-vue";

import LeftItem from "../LeftItem.vue";
import FooterItem from '../FooterItem.vue';
import authService from "../../../../services/auth.service";

export default {
  name: "Login",
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    LeftItem,
    FooterItem
  },
  data() {
    return {
      loading: false,
      message: "",
      email : null,
      password : null
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() {
    if (this.loggedIn) {
      this.$router.push("/pages/dashboards");
    }
  },
  methods: {
    handleLogin() {
      let user = {
        email : this.email,
        password : this.password
      };
      this.loading = true;
      authService.login(user).then(
        () => {
          //this.$router.push("/pages/dashboards");
          window.location.href = "/pages/dashboards";
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
